/* eslint-env browser */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import Amplify, { Analytics, AmazonPersonalizeProvider } from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import Routing from './components/Router';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
// <<Location1
import awsconfig from './aws-exports';
import awsvideoconfig from './aws-video-exports';

Analytics.addPluggable(new AmazonPersonalizeProvider());

Amplify.configure(awsconfig);
Amplify.configure(awsvideoconfig);
Analytics.configure({
  AmazonPersonalize: {

    // REQUIRED - The trackingId to track the events
    trackingId: '592f3bc4-fd52-446c-b145-48ed241dbeea',

    // OPTIONAL -  Amazon Personalize service region
    region: 'us-west-2',

    // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushSize: 10,

    // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    flushInterval: 5000, // 5s
  },
});
// Location1>>

const root = ReactDOM.createRoot(
  document.getElementById('root'),
);
root.render(<Routing />);

serviceWorker.unregister();
