import Chatbot from 'react-chatbot-kit';
import React, { useState } from 'react';
import 'react-chatbot-kit/build/main.css';
import ActionProvider from './ActionProvider';
import { ReactComponent as ButtonIcon } from './robot.svg';
import config from './config';
import MessageParser from './MessageParser';

import './index.css';

function CustomChatBot(/* props */) {
  const [showChatbot, toggleChatbot] = useState(false);
  const renderChatbot = (show) => {
    if (show) {
      return (
        <div className="app-chatbot-container">
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </div>
      );
    }
    return (<div />);
  };

  return (
    <div>
      <button
        className="app-chatbot-button"
        onClick={() => toggleChatbot((prev) => !prev)}
        type="button"
      >
        <ButtonIcon className="app-chatbot-button-icon" />
      </button>
      {renderChatbot(showChatbot)}
    </div>
  );
}

export default CustomChatBot;
