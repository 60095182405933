/* eslint-disable import/order */
import React, { Component } from 'react';

import {
  API, graphqlOperation,
} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import {
  Card, CardHeader,
} from 'reactstrap';
import Masonry from 'react-responsive-masonry';
import './index.css';

class RelatedFilms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }

  componentDidMount() {
    const { item, genre } = this.props;
    const query = {
      id: item.id,
    };
    if (item.us_maturity_rating !== 21) {
      query.rating = item.us_maturity_rating;
    }
    if (genre) {
      query.genre = genre;
    }
    API.graphql(graphqlOperation(queries.getRelatedVodAssets, query)).then((results) => {
      this.setState({
        items: results.data.getRelatedVodAssets.items,
      });
    });
  }

  movieSelected = (itemId, e) => {
    const { changeMovie, genre, item } = this.props;
    const query = {
      id: itemId,
    };
    if (item.us_maturity_rating !== 21) {
      query.rating = item.us_maturity_rating;
    }
    if (genre) {
      query.genre = genre;
    }
    API.graphql(graphqlOperation(queries.getRelatedVodAssets, query)).then((results) => {
      this.setState({
        items: results.data.getRelatedVodAssets.items,
      });
    });
    changeMovie(itemId, e);
  };

  getRelatedMovies = () => {
    const { items } = this.state;
    const table = items.map((item) => (
      <Card onClick={(e) => this.movieSelected(item.id, e)} key={item.id}>
        <CardHeader>{item.title}</CardHeader>
        {/* <CardImg top src={item.imageURL} alt="Card image cap" /> */}
        <img alt={`Poster of ${item.title}`} src={item.imageURL} />
      </Card>
    ));
    return (table);
  };

  render() {
    return (
      <ul>
        <Masonry columnsCount={3} gutter="10px">
          {this.getRelatedMovies()}
        </Masonry>
      </ul>
    );
  }
}

export default RelatedFilms;
