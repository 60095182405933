/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import VideoPlayer from '../VideoPlayer';
import MoreInfo from '../MoreInfo';

export default class VideoPopover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      hideClicked: false,
      item: { },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { hideClicked } = prevState;
    const { item, displayingMovie, sources } = nextProps;
    console.log(item);
    if (hideClicked && !displayingMovie) {
      return {
        show: false,
        hideClicked: false,
      };
    } if ('title' in item) {
      return {
        item,
        show: true,
        sources,
      };
    }
    return {
      show: false,
    };
  }

  hideMovie = () => {
    const { clearItem } = this.props;
    this.setState({
      hideClicked: true,
      item: { },
    });
    clearItem();
  };

  render() {
    const {
      cookie, changeMovie, genre, userId,
    } = this.props;
    const {
      item, item: { title }, sources,
    } = this.state;
    const { show } = this.state;
    const html5Settings = {
      hls: {
        overrideNative: true,
        cookie,
      },
    };
    console.log(`genre from vid ${genre}`);
    return (
      <Modal id="popup" style={{ maxWidth: 755 }} isOpen={show} toggle={this.hideMovie}>
        <ModalHeader toggle={this.hideMovie}>{title}</ModalHeader>
        <ModalBody>
          <VideoPlayer
            controls
            sources={sources}
            width={720}
            height={420}
            html5={html5Settings}
            cookie={cookie}
            userId={userId}
            matRating={item.us_maturity_rating}
          />
          <br />
          <MoreInfo item={item} changeMovie={changeMovie} genre={genre} />
        </ModalBody>
      </Modal>
    );
  }
}
