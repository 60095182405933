import React from 'react';
import {
  API, graphqlOperation,
} from 'aws-amplify';
import * as mutations from '../../graphql/mutations';

const sessionID = Math.floor(Math.random() * 10000000);

function ActionProvider({ createChatBotMessage, setState, children }) {
  const handleReco = (message) => {
    console.log(message, sessionID);
    API.graphql(graphqlOperation(
      mutations.sendMessage,
      { input: { sessionId: sessionID, message } },
    )).then((results) => {
      console.log(results.data);
      const botMessage = createChatBotMessage(
        results.data.sendMessage.response.replace(/\s?(<br\s?\/?>)\s?/g, '\r\n'),
      );
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    });
  };

  return (
    <div>
      {React.Children.map(children, (child) => React.cloneElement(child, {
        actions: {
          handleReco,
        },
      }))}
    </div>
  );
}

export default ActionProvider;
