/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Badge,
} from 'reactstrap';
import classnames from 'classnames';
import RelatedFilms from '../RelatedFilms';
import './index.css';

export default class MoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'imdb_tab',
    };
  }

  chooseTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  };

  colorMap = (key) => {
    switch (key) {
    case 'id':
      return 'primary';
    case 'year':
      return 'dark';
    case 'genres':
      return 'danger';
    case 'crewMembers':
      return 'warning';
    case 'castMembers':
      return 'info';
    case 'countries':
      return 'success';
    default:
      return 'secondary';
    }
  };

  titleMap = (key) => {
    switch (key) {
    case 'genres':
      return (<h5>Genres:</h5>);
    case 'crewMembers':
      return (<h5>Crew Members:</h5>);
    case 'castMembers':
      return (<h5>Cast Members:</h5>);
    case 'countries':
      return (<h5>Countries:</h5>);
    default:
      return (<h5>General:</h5>);
    }
  };

  ratingKeyMap = (number) => {
    if (number === 21) {
      return 'secondary';
    }
    if (number < 13) {
      return 'success';
    }
    if (number < 17) {
      return 'warning';
    }
    return 'danger';
  };
  // id
  // title
  // plot
  // year
  // imageURL
  // genres
  // crewMembers {
  //   id
  //   category
  //   name
  // }
  // castMembers {
  //   id
  //   category
  //   name
  // }
  // countries

  generateBadges = () => {
    const { item } = this.props;
    let badges = [];
    badges.push(<h5>General:</h5>);
    if (item) {
      Object.keys(item).forEach((key) => {
        if (key !== 'title' && key !== 'imageURL' && key !== 'plot' && key !== 'us_maturity_rating') {
          const val = item[key];
          if (typeof val === 'string' || typeof val === 'number') {
            if (key === 'us_maturity_rating_string') {
              if (item.us_maturity_rating !== 21) {
                badges.push(<Badge color={this.ratingKeyMap(item.us_maturity_rating)} key={val} style={{ margin: '3px' }}>{`Rating: ${val}`}</Badge>);
              } else {
                badges.push(<Badge color={this.ratingKeyMap(item.us_maturity_rating)} key={val} style={{ margin: '3px' }}>Not Rated</Badge>);
              }
            } else {
              badges.push(<Badge color={this.colorMap(key)} key={val} style={{ margin: '3px' }}>{val}</Badge>);
            }
          } else if (Array.isArray(val)) {
            const newBadges = val.map((arrayVal) => {
              if (typeof arrayVal === 'object' && arrayVal !== null) {
                return (<Badge color={this.colorMap(key)} key={arrayVal.id} style={{ margin: '3px' }}>{arrayVal.name}</Badge>);
              }
              return (<Badge color={this.colorMap(key)} key={arrayVal} style={{ margin: '3px' }}>{arrayVal}</Badge>);
            });
            badges.push(this.titleMap(key));
            badges = badges.concat(newBadges);
          }
        }
      });
    }
    return (badges);
  };

  render() {
    const { item, changeMovie, genre } = this.props;
    const { activeTab } = this.state;
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === 'imdb_tab' })} onClick={(e) => this.chooseTab('imdb_tab', e)}>
              <div className="boxAlign">
                <img src={`${process.env.PUBLIC_URL}/logos/IMDb_Logo_Rectangle_Gold.png`} alt="IMDb" className="tablogo" />
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classnames({ active: activeTab === 'related_tab' })} onClick={(e) => this.chooseTab('related_tab', e)}>
              You may also like
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="imdb_tab">
            <br />
            {this.generateBadges()}
            <br />
            <h5>Plot:</h5>
            <div>
              {item.plot}
            </div>
          </TabPane>
          <TabPane tabId="related_tab">
            <RelatedFilms item={item} changeMovie={changeMovie} genre={genre} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
