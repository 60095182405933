import React, { Component } from 'react';
import {
  Auth, Analytics,
} from 'aws-amplify';
import './App.css';
import CarouselView from '../CarouselView';

// <<Location7
// Location7>>
import NavBar from '../NavBar';
import CustomChatBot from '../ChatBot';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
    };
  }

  componentDidMount() {
    Auth.currentSession()
      .then((data) => {
        const userID = data.idToken.payload.sub;
        console.log(userID);
        this.setState({ username: data.idToken.payload['cognito:username'] });
        Analytics.record({
          eventType: 'Identify',
          properties: {
            userId: userID,
          },
        }, 'AmazonPersonalize');
      });
  }

  render() {
    console.log('We are here?');
    const { username } = this.state;
    const realUsername = username.charAt(0).toUpperCase() + username.slice(1);
    return (
      <div className="App">
        <header className="App-header" ref={this.viewRef}>
          <NavBar />
          <div style={{ height: 100 }} />
          <CarouselView username={realUsername} />
          <CarouselView filter="Trending" />
          <CarouselView filter="Batch" />
          <CarouselView filter="Action" />
          <CarouselView filter="Comedy" />
          <CarouselView filter="Drama" />
          <CarouselView filter="Horror" />
          <CarouselView filter="Animation" />
          <CarouselView filter="Sci-Fi" />
          <CarouselView filter="Documentary" />
          <CarouselView filter="Crime" />
          <CarouselView filter="Romance" />
          <CarouselView filter="Gems" username={realUsername} />
          <CarouselView filter="MostPopular" username={realUsername} />
          <CarouselView filter="Rated" username={realUsername} />
          <CarouselView filter="Watched" />
          <div style={{ height: 100 }} />
          <CustomChatBot />

          {/* <GridView /> */}
        </header>
      </div>
    );
  }
}

export default App;
