import React, { Component } from 'react';
import {
  Form,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { API, graphqlOperation, Analytics } from 'aws-amplify';
import VideoPopover from '../VideoPopover';
import * as queries from '../../graphql/queries';
import awsvideo from '../../aws-video-exports';
import './index.css';

class Search extends Component {
  constructor(props) {
    super(props);
    this.handleChangeElastic = this.handleChangeElastic.bind(this);
    this.handleChangeKendra = this.handleChangeKendra.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      elasticSearch: false,
      dropdownOpen: false,
      search: '',
      displayingMovie: false,
      choosenItem: {},
      sources: [],
    };
  }

  handleChange = async (event) => {
    const { elasticSearch } = this.state;
    if (elasticSearch) {
      await this.handleChangeElastic(event);
    } else {
      await this.handleChangeKendra(event);
    }
  };

  displayMovie = async (itemId) => {
    // <<Location13
    Analytics.record(
      {
        eventType: '<EVENT_TYPE>',
        properties: {
          itemId,
          eventValue: 1.0,
        },
      },
      'AmazonPersonalize',
    );
    API.graphql(graphqlOperation(
      queries.getVideoObject,
      { id: '085feb2c-6e9f-5fb1-9c20-ead8479b4d5f' },
    )).then((result) => {
      this.setState({
        cookie: result.data.getVideoObject.token,
        sources: [{
          src: `https://${awsvideo.awsOutputVideo}/output/085feb2c-6e9f-5fb1-9c20-ead8479b4d5f.m3u8`,
          type: 'application/x-mpegURL',
        }],
      });
    });
    // console.log(movie);
    const item = await API.graphql(graphqlOperation(queries.getVodAsset, { id: itemId }));
    this.setState({
      displayingMovie: true,
      choosenItem: item.data.getVodAsset,
    });
  };

  clearItem = () => {
    this.setState({
      displayingMovie: false,
      choosenItem: {},
    });
  };

  overlayMovie = () => {
    const {
      displayingMovie, sources, choosenItem, cookie,
    } = this.state;
    return (
      <VideoPopover
        sources={sources}
        displayingMovie={displayingMovie}
        item={choosenItem}
        cookie={cookie}
        changeMovie={this.displayMovie}
        clearItem={this.clearItem}
      />
    );
  };

  handleChangeKendra = async (event) => {
    this.setState({
      search: event.target.value,
    });
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(async () => {
      const { search } = this.state;
      if (search === '') {
        console.log('search empty');
        return;
      }
      API.graphql(graphqlOperation(queries.searchVodAsset, { searchString: search })).then(
        (results) => {
          this.setState({
            searchResults: results.data.searchVodAsset.items,
            dropdownOpen: true,
          });
        },
      );
    }, 500);
  };

  handleChangeElastic = async (event) => {
    this.setState({
      search: event.target.value,
    });
    clearTimeout(this.timeoutID);

    this.timeoutID = setTimeout(async () => {
      const { search } = this.state;
      const { setData } = this.props;
      const terms = search.split(' ');
      if (search === '') {
        setData(null);
        return;
      }
      if (terms.length === 1) {
        const searchFilter = { filter: { title: { wildcard: `${search}*` } }, limit: 50 };
        const assets = await API.graphql(graphqlOperation(queries.searchVodAssets, searchFilter));
        console.log(assets.data.searchVodAssets);
        const searchResults = {
          rawSearch: search,
          generatedSearch: searchFilter,
          items: assets.data.searchVodAssets.items,
          token: assets.data.searchVodAssets.nextToken,
        };
        setData(searchResults);
      } else {
        // BUGGGY AF :P
        const manyTerms = terms.map(() => ({ title: { match: `${search}` } }));
        const assets = await API.graphql(graphqlOperation(
          queries.searchVodAssets,
          {
            filter: { or: manyTerms },
            limit: 50,
          },
        ));
        console.log(assets);
      // this.props.setData(assets);
      }
    }, 500);
  };

  toggle = () => {
    const { dropdownOpen, search } = this.state;
    if (search === '') {
      this.setState({
        dropdownOpen: false,
      });
    } else {
      this.setState({
        dropdownOpen: !dropdownOpen,
      });
    }
  };

  searchItems = () => {
    const { searchResults } = this.state;
    if (searchResults && searchResults.length > 0) {
      const results = searchResults.map((item) => (
        <ListGroupItem
          onClick={(e) => this.displayMovie(item.id, e)}
          key={item.id}
        >
          {item.title}
        </ListGroupItem>
      ));
      return (results);
    }
    return (<div>No results found</div>);
  };

  render() {
    const { dropdownOpen } = this.state;
    return (
      <Form>
        <Dropdown isOpen={dropdownOpen} toggle={this.toggle} inNavbar>
          <DropdownToggle
            tag="span"
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
          >
            <Input
              placeholder="Search"
              className="searchBar"
              style={{
                width: '100%',
                borderRightWidth: 0,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                borderRadius: 0,
                paddingLeft: 0,
                color: 'white',
              }}
              onChange={this.handleChange}
            />
          </DropdownToggle>
          <DropdownMenu style={{ padding: 0 }}>
            <ListGroup>
              {this.searchItems()}
            </ListGroup>
          </DropdownMenu>
        </Dropdown>
        {this.overlayMovie()}
      </Form>
    );
  }
}

export default Search;
