import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

function GridCardView(props) {
  const {
    item: {
      imageURL, title, promo, id,
    }, filter,
  } = props;
  let classNameString = 'artwork';
  if (promo && filter !== 'Trending') {
    classNameString = 'artwork promo';
  } else if (id === 'tt27480852') {
    classNameString = 'artwork special';
  }
  return (
    <div className="caroCard">
      <div className="container">
        <img src={imageURL} alt={title} className={classNameString} />
        <img src={`${process.env.PUBLIC_URL}/logos/IMDb_Logo_Rectangle_Gold.png`} alt="IMDb" className="logo" />
      </div>
    </div>
  );
}

GridCardView.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    imageURL: PropTypes.string,
    details: PropTypes.string,
  }),
};

GridCardView.defaultProps = {
  item: {
    title: 'Default Title',
    imageURL: '',
    details: 'Default Details',
  },
};

export default GridCardView;
