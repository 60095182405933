import React from 'react';

function MessageParser({ children, actions }) {
  const parse = (message) => {
    console.log(message);
    actions.handleReco(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => React.cloneElement(child, {
        parse,
        actions: {},
      }))}
    </div>
  );
}

export default MessageParser;
