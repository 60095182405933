/* eslint-disable react/button-has-type */
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import App from '../App';
import Login from '../Login';
import Admin from '../Admin';
import RequireAuth from './RequireAuth';

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RequireAuth><App /></RequireAuth>} />
        <Route path="/Admin" element={<RequireAuth><Admin /></RequireAuth>} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

function Routing() {
  return (
    <Authenticator.Provider>
      <MyRoutes />
    </Authenticator.Provider>
  );
}

export default Routing;
