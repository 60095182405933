/* eslint-disable import/order */
/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import './index.css';
import VideoPopover from '../VideoPopover';
import GridCardView from '../GridCardView';
import SlideButton from '../SlideButtons';
import 'video.js/dist/video-js.css';
// <<Location9
import {
  API, graphqlOperation, Analytics, Auth,
} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
// Location9>>
// <<Location12
// import awsvideo from '../../aws-video-exports';
// Location12>>

class CarouselView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayingMovie: false,
      choosenItem: {},
      sources: [],
      items: [],
      activeItemIndex: 0,
      slideDistance: -75,
      username: props.username,
      overrideTitle: false,
      title: 'None',
    };
    this.viewRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const newState = state;
    const { username } = props;
    newState.username = username;
    return newState;
  }

  componentDidMount() {
    Auth.currentSession()
      .then((data) => {
        this.setState({ userId: data.idToken.payload.sub });
        console.log(`sub ${data.idToken.payload.sub}`);
      });
    const { width } = this.useSizeElement();
    const { filter } = this.props;
    const input = {};
    if (filter) {
      if (filter === 'adminPage') {
        API.graphql(graphqlOperation(queries.listPromoItems, input)).then((results) => {
          this.setState({ items: results.data.listPromoItems.items, width });
        }).catch((e) => {
          console.log(`Error with returning:${filter}, ${e} `);
        });
      } else if (filter === 'Batch') {
        API.graphql(graphqlOperation(queries.getBatch)).then((results) => {
          console.log(results.data.getBatch.title);
          this.setState({
            items: results.data.getBatch.items,
            width,
            overrideTitle: true,
            title: results.data.getBatch.title,
          });
        }).catch((e) => {
          console.log(`Error with returning:${filter}, ${e} `);
        });
      } else {
        input.filter = filter;
        API.graphql(graphqlOperation(queries.listTopVodAssets, input)).then((results) => {
          this.setState({ items: results.data.listTopVodAssets.items, width });
        }).catch((e) => {
          console.log(`Error with returning:${filter}, ${e} `);
        });
      }
    } else {
      API.graphql(graphqlOperation(queries.listTopVodAssets, input)).then((results) => {
        this.setState({ items: results.data.listTopVodAssets.items, width });
      }).catch((e) => {
        console.log(`Error with returning:${filter}, ${e} `);
      });
    }
  }

  useSizeElement = () => {
    const width = this.viewRef.current.clientWidth;
    return { width };
  };

  handlePrev = () => {
    const { activeItemIndex } = this.state;
    const newActive = activeItemIndex - 1;
    if (newActive >= 0) {
      this.setLocation(newActive);
    }
  };

  setLocation = (active) => {
    this.setState({
      activeItemIndex: active,
      slideDistance: (active * -176) - 75,
    });
  };

  handleNext = () => {
    const width = this.viewRef.current.clientWidth;
    const { activeItemIndex, items } = this.state;
    const currentCount = items.length;
    const totalInView = Math.floor(width / 176) - 1;
    const newActive = activeItemIndex + 1;
    if (newActive < currentCount - totalInView) {
      this.setLocation(newActive);
    }
  };

  displayMovie = async (itemId) => {
    // <<Location13
    console.log(itemId);
    const { filter } = this.props;
    const { items } = this.state;
    const movieID = itemId === 'tt27480852' ? 'race2cloud' : '085feb2c-6e9f-5fb1-9c20-ead8479b4d5f';
    if (filter && filter === 'adminPage') {
      console.log('promo add ');
      const itemInfo = items.filter((itemFilt) => (itemFilt.id === itemId))[0];
      console.log(itemInfo);
      itemInfo.promo = 'true';
      itemInfo.vodAssetVideoId = 1;
      itemInfo.directorInfo = itemInfo.crewMembers[0];
      itemInfo.crewMembers = itemInfo.crewMembers.map((crew) => ({ id: crew.id }));
      itemInfo.genreString = itemInfo.genres.join('|');
      delete itemInfo.video;
      console.log(itemInfo);
      API.graphql(graphqlOperation(mutations.promote, { input: itemInfo })).then((result) => {
        console.log(result);
      }).catch((e) => {
        console.log(`Error with returning: ${e} `);
      });
    } else {
      Analytics.record(
        {
          eventType: 'watch',
          properties: {
            itemId,
            eventValue: 1.0,
          },
        },
        'AmazonPersonalize',
      );
      API.graphql(graphqlOperation(
        queries.getVideoObject,
        { id: movieID },
      )).then((result) => {
        this.setState({
          cookie: result.data.getVideoObject.token,
          sources: [{
            // src: `https://${awsvideo.awsOutputVideo}/output/demo-video-tears.m3u8`,
            src: `https://d2jyprsbv583cq.cloudfront.net/v1/master/fc9f3147b8d67f4a9f0fe017b2355d9fc545ae27/mediatailor-personalize-demo/output/${movieID === 'race2cloud' ? 'race2cloud' : 'demo-video-tears'}.m3u8`,
            type: 'application/x-mpegURL',
          }],
        });
      });
      const item = await API.graphql(graphqlOperation(queries.getVodAsset, { id: itemId }));
      this.setState({
        displayingMovie: true,
        choosenItem: item.data.getVodAsset,
      });
    }
  };

  clearItem = () => {
    this.setState({
      displayingMovie: false,
      choosenItem: {},
    });
  };

  overlayMovie = () => {
    const {
      displayingMovie, sources, choosenItem, cookie, userId,
    } = this.state;
    const {
      filter,
    } = this.props;
    let genre = '';
    if (!['Watched', 'Gems', 'MostPopular', 'Rated'].includes(filter)) {
      genre = filter;
    }
    return (
      <VideoPopover
        sources={sources}
        displayingMovie={displayingMovie}
        item={choosenItem}
        cookie={cookie}
        changeMovie={this.displayMovie}
        clearItem={this.clearItem}
        userId={userId}
        genre={genre}
      />
    );
  };

  drawTitle = () => {
    const { filter } = this.props;
    const { username, overrideTitle, title } = this.state;
    if (filter) {
      let titleString = '';
      switch (filter) {
      case 'Batch':
        if (overrideTitle) {
          titleString = title;
          break;
        }
        titleString = 'n/a';
        break;
      case 'Trending':
        titleString = 'Trending Now';
        break;
      case 'Watched':
        titleString = 'Your recently watched';
        break;
      case 'Gems':
        titleString = `${username} Hidden Gems`;
        break;
      case 'MostPopular':
        titleString = `${username} Popular Picks`;
        break;
      case 'Rated':
        titleString = `${username} Highly Rated`;
        break;
      default:
        titleString = filter;
        break;
      }
      return (
        <h3 className="carouselTitle">
          { titleString }
        </h3>
      );
    }
    if (username) {
      return (
        <h3 className="carouselTitle">
          { `Top picks for ${username}` }
        </h3>
      );
    }
    return (
      <h3 className="carouselTitle">
        Generic Shelf
      </h3>
    );
  };

  render() {
    const {
      items,
      width,
      slideDistance,
    } = this.state;

    const { filter } = this.props;
    const style = {
      transform: `translate3d(${slideDistance}px, 0, 0)`,
    };
    const itemHTML = items.map((item) => (
      <button type="button" className="carouselCard" onClick={(e) => this.displayMovie(item.id, e)} aria-label={item.title} key={item.id}><GridCardView item={item} filter={filter} /></button>
    ));
    if (items.length < 2) {
      return (<div ref={this.viewRef} />);
    }
    return (
      <div ref={this.viewRef}>
        <div className="carouselFrame">

          {this.drawTitle()}
          <SlideButton onClick={this.handlePrev} type="prev" width={width} />
          <SlideButton onClick={this.handleNext} type="next" width={width} />
          <div className="carouselContainer" style={style}>
            {itemHTML}
          </div>
        </div>

        {this.overlayMovie()}
      </div>
    );
  }
}

export default CarouselView;
