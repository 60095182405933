// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:6d5053e6-2bb3-4d84-a4da-59867dccf854",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_LdCwjTNaM",
    "aws_user_pools_web_client_id": "b7rgajo9l3qc4g1hio20rh0bm",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://b5ge27vbgzggpgzkezcphvvs5u.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
