/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVodAsset = /* GraphQL */ `
  mutation CreateVodAsset(
    $input: CreateVodAssetInput!
    $condition: ModelvodAssetConditionInput
  ) {
    createVodAsset(input: $input, condition: $condition) {
      id
      title
    }
  }
`;

export const sendMessage = /* GraphQL */ `
  mutation sendMessage(
    $input: SendMessageInput!
  ) {
    sendMessage(input: $input) {
      response
    }
  }
`;

export const promote = /* GraphQL */ `
  mutation promote(
    $input: CreateVodAssetInput!
  ) {
    promote(input: $input) {
      id
      title
    }
  }
`;

export const updateVodAsset = /* GraphQL */ `
  mutation UpdateVodAsset(
    $input: UpdateVodAssetInput!
    $condition: ModelvodAssetConditionInput
  ) {
    updateVodAsset(input: $input, condition: $condition) {
      id
      title
      plot
      year
      imageURL
      genres
      crewMembers {
        id
        category
        name
      }
      castMembers {
        id
        category
        name
      }
      countries
      video {
        id
        token
      }
    }
  }
`;
export const deleteVodAsset = /* GraphQL */ `
  mutation DeleteVodAsset(
    $input: DeleteVodAssetInput!
    $condition: ModelvodAssetConditionInput
  ) {
    deleteVodAsset(input: $input, condition: $condition) {
      id
      title
      plot
      year
      imageURL
      genres
      crewMembers {
        id
        category
        name
      }
      castMembers {
        id
        category
        name
      }
      countries
      video {
        id
        token
      }
    }
  }
`;
export const createPersonalInfo = /* GraphQL */ `
  mutation CreatePersonalInfo(
    $input: CreatePersonalInfoInput!
    $condition: ModelpersonalInfoConditionInput
  ) {
    createPersonalInfo(input: $input, condition: $condition) {
      id
      category
      name
    }
  }
`;
export const updatePersonalInfo = /* GraphQL */ `
  mutation UpdatePersonalInfo(
    $input: UpdatePersonalInfoInput!
    $condition: ModelpersonalInfoConditionInput
  ) {
    updatePersonalInfo(input: $input, condition: $condition) {
      id
      category
      name
    }
  }
`;
export const deletePersonalInfo = /* GraphQL */ `
  mutation DeletePersonalInfo(
    $input: DeletePersonalInfoInput!
    $condition: ModelpersonalInfoConditionInput
  ) {
    deletePersonalInfo(input: $input, condition: $condition) {
      id
      category
      name
    }
  }
`;
export const createVideoObject = /* GraphQL */ `
  mutation CreateVideoObject(
    $input: CreateVideoObjectInput!
    $condition: ModelvideoObjectConditionInput
  ) {
    createVideoObject(input: $input, condition: $condition) {
      id
      token
    }
  }
`;
export const updateVideoObject = /* GraphQL */ `
  mutation UpdateVideoObject(
    $input: UpdateVideoObjectInput!
    $condition: ModelvideoObjectConditionInput
  ) {
    updateVideoObject(input: $input, condition: $condition) {
      id
      token
    }
  }
`;
export const deleteVideoObject = /* GraphQL */ `
  mutation DeleteVideoObject(
    $input: DeleteVideoObjectInput!
    $condition: ModelvideoObjectConditionInput
  ) {
    deleteVideoObject(input: $input, condition: $condition) {
      id
      token
    }
  }
`;

export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo($input: ID!) {
    createUserInfo(input: $input) {
      id
      personalizeId
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo($input: ID!) {
    updateUserInfo(input: $input) {
      id
      personalizeId
    }
  }
`;
