/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      personalizeId
    }
  }
`;

export const getVodAsset = /* GraphQL */ `
  query GetVodAsset($id: ID!) {
    getVodAsset(id: $id) {
      id
      us_maturity_rating
      us_maturity_rating_string
      title
      plot
      year
      imageURL
      genres
      crewMembers {
        id
        category
        name
      }
      castMembers {
        id
        category
        name
      }
      countries
      video {
        id
        token
      }
    }
  }
`;
export const getRelatedVodAssets = /* GraphQL */ `
  query GetRelatedVodAssets(
    $id: ID!
    $rating: Int 
    $genre: String
    ) {
    getRelatedVodAssets(id: $id, rating: $rating, genre: $genre) {
      items {
        id
        title
        plot
        year
        imageURL
        genres
        crewMembers {
          id
          category
          name
        }
        castMembers {
          id
          category
          name
        }
        countries
        video {
          id
          token
        }
      }
      nextToken
    }
  }
`;
export const listVodAssets = /* GraphQL */ `
  query ListVodAssets(
    $filter: ModelvodAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVodAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        plot
        year
        imageURL
        genres
        crewMembers {
          id
          category
          name
        }
        castMembers {
          id
          category
          name
        }
        countries
        video {
          id
          token
        }
      }
      nextToken
    }
  }
`;

export const listPromoItems = /* GraphQL */ `
  query listPromoItems($filter: String) {
    listPromoItems(filter: $filter) {
      items {
        id
        title
        plot
        year
        imageURL
        us_maturity_rating
        us_maturity_rating_string
        genres
        crewMembers {
          id
          name
        }
        castMembers {
          id
        }
        countries
        video {
          id
        }
      }
      nextToken
    }
  }
`;

export const listTopVodAssets = /* GraphQL */ `
  query ListTopVodAssets($filter: String) {
    listTopVodAssets(filter: $filter) {
      items {
        id
        title
        plot
        year
        promo
        imageURL
        genres
        crewMembers {
          id
        }
        castMembers {
          id
        }
        countries
        video {
          id
        }
      }
      nextToken
    }
  }
`;

export const getBatch = /* GraphQL */ `
  query getBatch{
    getBatch {
      title
      items {
        id
        title
        plot
        year
        promo
        imageURL
        genres
        crewMembers {
          id
        }
        castMembers {
          id
        }
        countries
        video {
          id
        }
      }
      nextToken
    }
  }
`;
export const getPersonalInfo = /* GraphQL */ `
  query GetPersonalInfo($id: ID!) {
    getPersonalInfo(id: $id) {
      id
      category
      name
    }
  }
`;
export const listPersonalInfos = /* GraphQL */ `
  query ListPersonalInfos(
    $filter: ModelpersonalInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonalInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        name
      }
      nextToken
    }
  }
`;
export const getVideoObject = /* GraphQL */ `
  query GetVideoObject($id: ID!) {
    getVideoObject(id: $id) {
      id
      token
    }
  }
`;
export const listVideoObjects = /* GraphQL */ `
  query ListVideoObjects(
    $filter: ModelvideoObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        token
      }
      nextToken
    }
  }
`;
export const searchVodAssets = /* GraphQL */ `
  query SearchVodAssets(
    $filter: SearchablevodAssetFilterInput
    $sort: SearchablevodAssetSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchVodAssets(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        plot
        year
        imageURL
        genres
        crewMembers {
          id
          category
          name
        }
        castMembers {
          id
          category
          name
        }
        countries
        video {
          id
          token
        }
      }
      nextToken
      total
    }
  }
`;
export const searchVodAsset = /* GraphQL */ `
  query SearchVodAsset($searchString: String!) {
    searchVodAsset(searchString: $searchString) {
      items {
        id
        title
        plot
        year
        imageURL
        genres
        crewMembers {
          id
          category
          name
        }
        castMembers {
          id
          category
          name
        }
        countries
        video {
          id
          token
        }
      }
      nextToken
    }
  }
`;
