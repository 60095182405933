import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  Auth,
} from 'aws-amplify';
import Search from '../Search';
import './index.css';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isDropdown: false,
      username: 'hold',
    };
    this.toggle = this.toggle.bind(this);
    this.toggleDD = this.toggleDD.bind(this);
    this.signout = this.signout.bind(this);
  }

  componentDidMount() {
    // <<Location5
    Auth.currentSession()
      .then((data) => {
        const groups = data.idToken.payload['cognito:groups'];
        if (groups) {
          this.setState({ groups: data.idToken.payload['cognito:groups'] });
        }
        this.setState({ username: data.idToken.payload['cognito:username'] });
      });
    // Location5>>
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  };

  toggleDD = () => {
    const { isDropdown } = this.state;
    this.setState({
      isDropdown: !isDropdown,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  signout = () => {
    Auth.signOut();
  };

  dropdown = () => {
    const { username, groups, isDropdown } = this.state;
    let adminPage;
    if (groups && groups.includes('Admin')) {
      adminPage = (
        <DropdownItem href="/admin" style={{ color: '#E1E4E4' }} className="customDropItem">
          Admin Page (v2)
        </DropdownItem>
      );
    }
    return (
      <Dropdown nav isOpen={isDropdown} toggle={this.toggleDD}>
        <DropdownToggle nav caret style={{ color: '#E1E4E4', fontSize: '0.75em' }}>
          Profile
        </DropdownToggle>
        <DropdownMenu inNavbar end className="navDrop">
          <DropdownItem header style={{ color: '#E1E4E4' }}>
            Hello
            {' '}
            {username}
          </DropdownItem>
          {adminPage}
          <DropdownItem divider />
          <DropdownItem onClick={this.signout} style={{ color: '#E1E4E4' }} className="customDropItem">
            Sign Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  render() {
    const { isOpen } = this.state;
    return (
      <Navbar dark expand="md" fixed="top" className="navTop" style={{ backgroundColor: '#ffffff00' }}>
        <NavbarBrand href="/" style={{ fontSize: '1em', color: '#E1E4E4' }}>UnicornFlix</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar />
          <Search />
          {this.dropdown()}
        </Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
