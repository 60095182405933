/* eslint-disable import/order */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import './index.css';
// <<Location2
// Location2>>
// <<Location4
import {
  Auth,
// API, graphqlOperation,
} from 'aws-amplify';

import CarouselView from '../CarouselView';
import NavBar from '../NavBar';
// Location4>>

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
    };
    this.createAdminPanel = this.createAdminPanel.bind(this);
  }

  componentDidMount() {
    // <<Location5
    Auth.currentSession()
      .then((data) => {
        const groups = data.idToken.payload['cognito:groups'];
        if (groups) {
          this.setState({ groups: data.idToken.payload['cognito:groups'] });
        }
      });

    // Location5>>
  }

  createAdminPanel() {
    const {
      groups,
    } = this.state;
    if (groups.includes('Admin')) {
      return (
        <div className="App">
          <header className="App-header" ref={this.viewRef}>
            <NavBar />
            <div style={{ height: 100 }} />
            <CarouselView filter="adminPage" />
          </header>
        </div>
      );
    }
    return (
      <div>
        Not Authenticated
      </div>
    );
  }

  render() {
    return (
      <div className="adminHeader">
        {this.createAdminPanel()}
      </div>
    );
  }
}
// <<Location3
export default Admin;
// Location3>>
