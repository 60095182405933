/* eslint-disable */
import React from 'react';
import videojs from 'video.js';
import './index.css';

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sources: [
        {
          src: '',
        },
      ],
    };
  }

  componentDidMount() {
    const {
      userId, matRating
    } = this.props;
    videojs.options.vhs.overrideNative = true;
    videojs.Hls.xhr.beforeRequest = function (options) {
      // options.uri = `${options.uri}${videojs.getAllPlayers()[0].options().cookie}`;
      if (options.uri.includes('v1/')){
        options.uri = `${options.uri}?ads.userId=${userId}&ads.matRating=${matRating}`;
      } else {
        options.uri = `${options.uri}${videojs.getAllPlayers()[0].options().cookie}`;
      }
      // options.uri = `${options.uri}?ads.param1=0483d02c-5c05-4297-9b64-a6d6c75d2ae9`;
      return options;
    };
    this.player = videojs(this.videoNode, this.props);
    console.log(this.player);
  }

  static getDerivedStateFromProps(nextProps) {
    return nextProps;
  }

  componentDidUpdate() {
    this.player.src(this.state.sources);
    this.player.options(this.state);
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  render() {
    return (
      <div>
        <div data-vjs-player>
          <video ref={(node) => { this.videoNode = node; }} className="video-js vjs-big-play-centered" />
        </div>
      </div>
    );
  }
}
