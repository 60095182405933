import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';

const botName = 'MovieBot';

const config = {
  initialMessages: [createChatBotMessage(`Hi! I'm ${botName}, ask me about movie recommendations.`)],
  botName,
  customComponents: {
    header: () => <div className="react-chatbot-kit-chat-header">MovieBot</div>,
  },
};

export default config;
